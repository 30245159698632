<app-client-header-logged-out *ngIf="headerService.headerType === 'clientHeaderLoggedOut'"> </app-client-header-logged-out>
<app-client-header-logo-only *ngIf="headerService.headerType === 'logoOnly'"></app-client-header-logo-only>
<!-- <app-client-header *ngIf="headerService.headerType === 'clientHeader'"></app-client-header> -->
<app-loan-application-header [headerText]="headerService.headerText" *ngIf="headerService.headerType === 'clientApplicationHeader'"></app-loan-application-header>
<div class="star-watermark-container">
	<img class="star-watermark" src="../../../../../assets/images/Black Star_2500px.png" />
</div>
<br />
<br />
<ngx-spinner [name]="'rootLoader'" size="medium" color="#fff" type="square-jelly-box" bdColor="rgba(255,255,255,0.8);" [fullScreen]="false">
	<div id="wrapper">
		<div class="gears">
			<div class="gears-container">
				<div class="gear-rotate"></div>
				<div class="loading-text-sec">
					<span class="loading-text">Loading...</span>
				</div>
			</div>
		</div>
	</div>
</ngx-spinner>
<router-outlet></router-outlet>
