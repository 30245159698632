import { Injectable } from '@angular/core';
import { SpinnerState } from '../models/model';
import { Subject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class RootLoaderService {
	rootLoaderState = new Subject<SpinnerState>();

	public showLoader() {
		const spinnerState: SpinnerState = SpinnerState.Show;
		this.rootLoaderState.next(spinnerState);
	}

	public hideLoader() {
		const spinnerState: SpinnerState = SpinnerState.Hide;
		this.rootLoaderState.next(spinnerState);
	}
}
